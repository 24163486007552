.intel-hero {
  position: relative;
  width: 100%;
  height: 91vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}

.menu-for-large {
  position: absolute;
  width: 100%;
  z-index: 2;
  bottom: 50px;
}

@media screen and (max-width: 768px) {
  .intel-hero {
    height: 60vh; /* Reduced from 100vh to 60vh for mobile */
    margin: 0;
    padding: 0;
  }

  .menu-for-large {
    bottom: 20px; /* Adjusted bottom spacing */
  }
  
  /* Ensure video player fits properly */
  .video-player {
    height: 60vh;
    object-fit: cover;
  }
}